a {
  text-decoration: none;
}

*:focus-visible {
  outline: none;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: rgba(144, 144, 203, 0.1);
  border-radius: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(188, 188, 224, 1);
  border-radius: 20px;
}
